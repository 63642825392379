import { Injectable } from '@angular/core';
import { WeightUnits } from '@shared/enums/weight-units.enum';
import { Apollo, gql } from 'apollo-angular';
import { Currency } from 'app/modules/organizations-manager/models/currency.model';
import { ServiceTypes } from 'app/modules/wizard/enums/service-types.enum';
import { ServiceCard } from 'app/modules/wizard/models/service-card/service-card.model';
import { ServiceOption } from 'app/modules/wizard/models/service-option.model';
import { Observable, forkJoin, map, of, switchMap } from 'rxjs';
import {
  DEFAULT_AIR_FREIGHT_LCL_VOLUME_RATES,
  DEFAULT_AIR_FREIGHT_LCL_VOLUME_WEIGHTS,
  DEFAULT_OCEAN_FREIGHT_LCL_VOLUME_RATES,
  DEFAULT_OCEAN_FREIGHT_LCL_VOLUME_WEIGHTS,
} from '../constants/lcl-rates';
import { ChargingRateTemplateTypes } from '../enums/charging-rates.enum';
import { BidService, BidServiceData } from '../models/bid-service-data.model';
import { LclRateStructure } from '../models/lcl-rate-structure.model';
import { FCLRate, LCLRate } from '../models/rate.model';

@Injectable({ providedIn: 'root' })
export class CreateBidService {
  constructor(private apollo: Apollo) {}

  getShipmentServiceOption(shipmentId: string, serviceOptionId: string): Observable<ServiceOption> {
    const QUERY = gql`
      query FindShipmentsById($ids: [String!]!) {
        findShipmentsByIds(ids: $ids) {
          serviceOptions {
            _id # service option id
            shipmentMode
            services {
              serviceId
              serviceName
              plannedServiceId
            }
          }
        }
      }
    `;
    return this.apollo
      .query<{
        findShipmentsByIds: { serviceOptions: ServiceOption[] };
      }>({ query: QUERY, variables: { ids: [shipmentId] } })
      .pipe(
        map(response => {
          return response.data.findShipmentsByIds[0].serviceOptions.find(option => {
            return option._id === serviceOptionId;
          });
        })
      );
  }

  getShipmentServiceOptions(shipmentId: string): Observable<ServiceOption[]> {
    const QUERY = gql`
      query FindShipmentsById($ids: [String!]!) {
        findShipmentsByIds(ids: $ids) {
          serviceOptions {
            _id # service option id
            shipmentMode
            services {
              serviceId
              serviceName
              plannedServiceId
            }
          }
        }
      }
    `;
    return this.apollo.query({ query: QUERY, variables: { ids: [shipmentId] } }).pipe(
      map(response => {
        return response.data['findShipmentsByIds'][0]['serviceOptions'] as ServiceOption[];
      })
    );
  }

  getServiceCard(shipmentId: string, serviceId: string, serviceOptionId: string): Observable<ServiceCard> {
    const QUERY = gql`
      query {
        findServiceCardByShipmentIdAndServiceOptionId(
          id: "${serviceId}"
          shipmentId: "${shipmentId}"
          serviceOptionId: "${serviceOptionId}"
        ) {
          loadDetails {
            containerType
            noOfLoads
            volumeWeight
          }
          cargoDetails {
            chargeableWeight
          }
          serviceId
          shipmentMethod
          transhipment
          routingDetails {
            transitTime
            shippingLine
            airline
            vessel
            flight
            voyage
            countryOfOrigin
            loadingLocation
            loadingLocationETA
            loadingLocationETD
            loadingTerminal
            loadingTerminalETA
            loadingTerminalETD
            countryOfDestination
            unloadingLocation
            unloadingLocationETA
            unloadingLocationETD
            unloadingTerminal
            unloadingTerminalETA
            unloadingTerminalETD
            cargoCutoff
            vgmCutoff
            cfsCutoff
            cyCutoff
            transitType
            bookingNumber
          }
        }
      }
    `;

    return this.apollo.query({ query: QUERY, variables: { shipmentId, serviceId, serviceOptionId } }).pipe(
      map(response => {
        const serviceCard = response.data['findServiceCardByShipmentIdAndServiceOptionId'] as ServiceCard;

        // Process to convert container type id to name because the backend returns the container type id instead of the name
        if (serviceCard.loadDetails && serviceCard.loadDetails.length > 0) {
          const containerTypeObservables = serviceCard.loadDetails.map(loadDetail => {
            if (loadDetail.containerType) {
              return this.getContainerTypeFromID(loadDetail.containerType);
            }
            return of('');
          });

          return forkJoin(containerTypeObservables).pipe(
            map(containerTypeNames => {
              const updatedLoadDetails = serviceCard.loadDetails.map((loadDetail, index) => ({
                ...loadDetail,
                containerType: containerTypeNames[index],
              }));

              return {
                ...serviceCard,
                loadDetails: updatedLoadDetails,
              };
            })
          );
        }
        return of(serviceCard);
      }),
      switchMap(result => result)
    );
  }

  getContainerTypeFromID(containerTypeId: string): Observable<string> {
    const QUERY = gql`
      query GetContainerType($id: String!) {
        containerType(id: $id) {
          name
        }
      }
    `;
    return this.apollo.query({ query: QUERY, variables: { id: containerTypeId } }).pipe(
      map(response => {
        return response.data['containerType']['name'] as string;
      })
    );
  }

  identifyChargingStructureTypeForService(serviceCard: ServiceCard): ChargingRateTemplateTypes | null {
    if (serviceCard.shipmentMethod === 'FCL' && serviceCard.serviceId === ServiceTypes.OCEAN_FREIGHT) {
      return ChargingRateTemplateTypes.DEFAULT_OCEAN_FREIGHT_FCL_RATE_CARD_TEMPLATE;
    } else if (serviceCard.shipmentMethod === 'LCL' && serviceCard.serviceId === ServiceTypes.OCEAN_FREIGHT) {
      return ChargingRateTemplateTypes.DEFAULT_OCEAN_FREIGHT_LCL_RATE_CARD_TEMPLATE;
    } else if (serviceCard.shipmentMethod === 'LCL' && serviceCard.serviceId === ServiceTypes.AIR_FREIGHT) {
      return ChargingRateTemplateTypes.DEFAULT_AIR_FREIGHT_LCL_RATE_CARD_TEMPLATE;
    } else {
      return null;
    }
  }

  // TODO: This is a temporary function to get the default rates for LCL, in a future iteration we will get this from the backend
  getLCLDefaultTemplate(requiredTemplateType: ChargingRateTemplateTypes): LclRateStructure {
    if (requiredTemplateType === ChargingRateTemplateTypes.DEFAULT_OCEAN_FREIGHT_LCL_RATE_CARD_TEMPLATE) {
      return {
        rates: DEFAULT_OCEAN_FREIGHT_LCL_VOLUME_RATES,
        weights: DEFAULT_OCEAN_FREIGHT_LCL_VOLUME_WEIGHTS,
        weightUnit: WeightUnits.KILOGRAM,
      };
    } else if (requiredTemplateType === ChargingRateTemplateTypes.DEFAULT_AIR_FREIGHT_LCL_RATE_CARD_TEMPLATE) {
      return {
        rates: DEFAULT_AIR_FREIGHT_LCL_VOLUME_RATES,
        weights: DEFAULT_AIR_FREIGHT_LCL_VOLUME_WEIGHTS,
        weightUnit: WeightUnits.KILOGRAM,
      };
    } else {
      return { rates: [], weights: [], weightUnit: WeightUnits.KILOGRAM };
    }
  }

  applyFCLRateCard(loadDetails: FCLRate, unitPrice: number): Observable<{ subTotal: number }> {
    const mutation = gql`
      mutation applyRateCardForService(
        $rateCardDataInput: RateCardDataInput!
        $serviceData: JSON!
        $rateCardId: String!
      ) {
        applyRateCardForService(
          rateCardDataInput: $rateCardDataInput
          serviceData: $serviceData
          rateCardId: $rateCardId
        )
      }
    `;

    const variables = {
      rateCardDataInput: {
        data: {
          values: {
            loadRates: [
              {
                shipmentMethod: 'FCL',
                containerTypeAndSize: loadDetails.containerType,
                unitPrice: unitPrice,
              },
            ],
          },
        },
      },
      serviceData: [
        {
          shipmentMethod: 'FCL',
          containerTypeAndSize: loadDetails.containerType,
          quantity: loadDetails.quantity,
        },
      ],
      rateCardId: ChargingRateTemplateTypes.DEFAULT_OCEAN_FREIGHT_FCL_RATE_CARD_TEMPLATE,
    };

    return this.apollo
      .mutate({
        mutation,
        variables,
      })
      .pipe(map(response => response.data['applyRateCardForService'] as { subTotal: number }));
  }

  applyLCLRateCard(
    weightUnit: string,
    volumeWeights: number[],
    rates: number[],
    serviceData: { weight: number },
    rateCardId: string
  ): Observable<{ subTotal: number }> {
    const mutation = gql`
      mutation ApplyRateCardForService(
        $rateCardDataInput: RateCardDataInput!
        $serviceData: JSON!
        $rateCardId: String!
      ) {
        applyRateCardForService(
          rateCardDataInput: $rateCardDataInput
          serviceData: $serviceData
          rateCardId: $rateCardId
        )
      }
    `;

    // Ensure the structure matches the backend schema
    const rateCardDataInput = {
      data: {
        values: {
          volumeWeightsRates: {
            volumeWeights: {
              title: 'Volume Weight',
              unit: weightUnit,
              values: volumeWeights,
            },
            rates: {
              title: 'Rate per ' + weightUnit,
              unit: weightUnit,
              values: rates,
            },
          },
        },
      },
    };

    const variables = {
      rateCardDataInput,
      serviceData,
      rateCardId,
    };

    return this.apollo
      .mutate({
        mutation,
        variables,
      })
      .pipe(map(response => response.data['applyRateCardForService'] as { subTotal: number }));
  }

  createBid(createBidInput: any): Observable<string> {
    const mutation = gql`
      mutation CreateBid($createBidInput: CreateBidInput!) {
        createBid(createBidInput: $createBidInput)
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          createBidInput, // Pass the createBidInput object here
        },
      })
      .pipe(map(response => response.data['createBid'] as string));
  }

  updateBid(updateBidInput: any): Observable<string> {
    const mutation = gql`
      mutation updateBidById($updateBidInput: UpdateBidInput!) {
        updateBidById(updateBidInput: $updateBidInput)
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          updateBidInput, // Pass the updateBidById object here
        },
      })
      .pipe(map(response => response.data['updateBidById'] as string));
  }

  sendBid(bidId: string): Observable<boolean> {
    const mutation = gql`
      mutation sendBid($bidId: String!) {
        sendBid(bidId: $bidId)
      }
    `;

    return this.apollo
      .mutate({
        mutation: mutation,
        variables: {
          bidId,
        },
      })
      .pipe(map(response => response.data['sendBid'] as boolean));
  }

  withdrawBid(bidId: string): Observable<boolean> {
    const mutation = gql`
      mutation WithdrawBid($bidId: String!) {
        withdrawBid(bidId: $bidId)
      }
    `;

    return this.apollo
      .mutate({
        mutation: mutation,
        variables: {
          bidId,
        },
      })
      .pipe(
        map(response => {
          console.log('response', response);
          return response.data['withdrawBid'] as boolean;
        })
      );
  }

  getMyBids(jobRefId: string): Observable<any> {
    const QUERY = gql`
      query findBidsByJobRefId($jobRefId: String!) {
        findBidsByJobRefId(jobRefId: $jobRefId) {
          _id
          status
          bidRefNumber
          bidMetaData {
            bidName
            accepted
            shipmentsData {
              shipmentId
              serviceOptionId
              serviceMetaData {
                rateCard {
                  rateCardData {
                    currencyCode
                  }
                }
                ratesSummary
              }
            }
          }
          createdAt
        }
      }
    `;

    return this.apollo.query({ query: QUERY, variables: { jobRefId } }).pipe(
      map(response => {
        return response.data['findBidsByJobRefId'];
      })
    );
  }

  getBidById(bidId: string): Observable<BidServiceData> {
    const QUERY = gql`
      query FindBidById($bidId: String!) {
        findBidById(bidId: $bidId) {
          _id
          bidMetaData {
            bidName
            favorite
            voteMetaData {
              noOfVotes
            }
            shipmentsData {
              shipmentId
              serviceOptionId
              serviceMetaData {
                serviceId
                ratesSummary
                rateCard {
                  rateCardData {
                    currencyCode
                    data
                  }
                }
              }
            }
          }
        }
      }
    `;
    return this.apollo.query({ query: QUERY, variables: { bidId } }).pipe(
      switchMap(response => {
        const bidData = response.data['findBidById'];
        console.log(bidData);
        const shipmentsData = bidData.bidMetaData.shipmentsData[0]; // Assuming we're dealing with the first bid option

        return this.getShipmentServiceOption(shipmentsData.shipmentId, shipmentsData.serviceOptionId).pipe(
          switchMap(serviceOption => {
            const serviceCardObservables = shipmentsData.serviceMetaData.map(service =>
              this.getServiceCard(shipmentsData.shipmentId, service.serviceId, serviceOption._id)
            );

            return forkJoin(serviceCardObservables).pipe(
              map(serviceCards => {
                const transformedBid: BidServiceData = {
                  id: bidData._id,
                  title: bidData.bidMetaData.bidName,
                  shipmentId: shipmentsData.shipmentId,
                  serviceOptionId: shipmentsData.serviceOptionId,
                  services: shipmentsData.serviceMetaData.map((service, index) => {
                    const additionalCharges = service.rateCard.rateCardData.data.additionalCharges || [];
                    const rates = service.rateCard.rateCardData.data.values || [];

                    const fclRates: FCLRate[] = rates.loadRates?.map((rate: any) => ({
                      shipmentMode: rate.shipmentMethod,
                      containerType: rate.containerTypeAndSize,
                      quantity: rate.quantity,
                      unitPrice: rate.unitPrice,
                    }));

                    const lclWeights: number[] =
                      service.rateCard.rateCardData.data?.values?.volumeWeightsRates?.volumeWeights;
                    const lclRates: number[] = service.rateCard.rateCardData.data?.values?.volumeWeightsRates?.rates;

                    const lclRateObject: LCLRate = {
                      volumeWeights: lclWeights,
                      ratePerUnit: lclRates,
                      weightUnit: WeightUnits.KILOGRAM,
                    };

                    return {
                      serviceData: {
                        serviceCard: serviceCards[index],
                        serviceName: serviceOption.services[index].serviceName || '',
                        serviceId: service.serviceId,
                      },
                      isDisabled: true,
                      subTotal: service.ratesSummary['subTotal'],
                      currency: { code: service.rateCard.rateCardData.currencyCode } as Currency,
                      additionalCharges: additionalCharges,
                      fclRates: fclRates,
                      lclRates: lclRateObject,
                    } as unknown as BidService;
                  }),
                  currency: { code: shipmentsData.serviceMetaData[0].rateCard.rateCardData.currencyCode } as Currency,
                  rates: [], // not implemented
                  total: shipmentsData.serviceMetaData.reduce((sum, service) => sum + service.ratesSummary['total'], 0),
                  favorite: bidData.bidMetaData.favorite,
                };

                return transformedBid;
              })
            );
          })
        );
      })
    );
  }

  getAllCurrencies(): Observable<Currency[]> {
    const QUERY = gql`
      query {
        getAllCurrencies {
          code
          currency
          shortCode
        }
      }
    `;
    return this.apollo.query({ query: QUERY }).pipe(map(response => response.data['getAllCurrencies'] as Currency[]));
  }
}
