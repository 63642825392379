import { Injectable } from '@angular/core';
import { DEFAULT_LIMIT, DEFAULT_OFFSET, MASKED_FLAG } from '@configs/constants';
import { NotificationService } from '@core/services/notification/notification.service';
import { Logout } from '@core/store/auth/auth.actions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ACCEPT_BID_OPTION_MUTATION,
  FETCH_BIDS_BY_TENDER_ID_QUERY,
  FETCH_BID_BY_ID_QUERY,
  GET_TOTAL_BIDS_BY_TENDER_ID_QUERY,
  MARK_BID_AS_FAVORITE_MUTATION,
  REJECT_ALL_BIDS_MUTATION,
  VOTE_BID_OPTION_MUTATION,
} from '@shared/gql-shared-queries';
import { Apollo } from 'apollo-angular';
import { catchError, of, tap } from 'rxjs';
import { Bid, VoteStatus } from '../../dto/bid.dto';
import { TenderStates } from '../../enums/tender-status.enum';
import {
  AwardBidAction,
  ClearBids,
  FetchAwardedBids,
  FetchBidByIdAction,
  FetchBidsByTenderIdAction,
  FetchBidsPaginated,
  FetchFavoriteBidsPaginated,
  FetchNonFavoriteBidsPaginated,
  FetchTotalBidsByTenderId,
  MarkAsFavoriteBidAction,
  RejectAllBidsAction,
  SetCurrentVoteAction,
  UpdateTenderStatusAction,
  VoteBidAction,
} from './procurement.actions';

export interface ProcurementStateModel {
  bids: {
    items: Bid[];
    pageInfo?: {
      total: number;
      pageSize: number;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
    };
  };
  favoriteBids: {
    items: Bid[];
    pageInfo?: {
      total: number;
      pageSize: number;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
    };
  };
  acceptedBids: Bid[];
  currentBid: Bid;
  currentTenderStatus: TenderStates;
  currentVote: VoteStatus;
  totalBidsForTender: number;
}

const defaults: ProcurementStateModel = {
  bids: {
    items: [],
    pageInfo: {
      total: 0,
      pageSize: DEFAULT_LIMIT,
      hasPreviousPage: false,
      hasNextPage: false,
    },
  },
  favoriteBids: {
    items: [],
    pageInfo: {
      total: 0,
      pageSize: DEFAULT_LIMIT,
      hasPreviousPage: false,
      hasNextPage: false,
    },
  },
  acceptedBids: [],
  currentBid: null,
  currentTenderStatus: TenderStates.PUBLISHED,
  currentVote: null,
  totalBidsForTender: 0,
};

@State<ProcurementStateModel>({
  name: 'procurement',
  defaults,
  children: [],
})
@Injectable()
export class ProcurementState {
  constructor(
    private apollo: Apollo,
    private notificationService: NotificationService
  ) {}

  @Selector()
  static getBids(state: ProcurementStateModel) {
    return state.bids.items;
  }

  @Selector()
  static getFavoriteBids(state: ProcurementStateModel) {
    return state.favoriteBids.items;
  }

  @Selector()
  static getAwardedBids(state: ProcurementStateModel) {
    return state.acceptedBids;
  }

  @Selector()
  static getCurrentBid(state: ProcurementStateModel) {
    return state.currentBid;
  }

  @Selector()
  static getCurrentTenderStatus(state: ProcurementStateModel): TenderStates {
    return state.currentTenderStatus;
  }

  @Selector()
  static getCurrentVote(state: ProcurementStateModel) {
    return state.currentVote;
  }

  @Selector()
  static getTotalBidsForTender(state: ProcurementStateModel) {
    return state.totalBidsForTender;
  }

  @Selector()
  static getTotalAllBids(state: ProcurementStateModel) {
    return state.bids.pageInfo.total;
  }

  @Selector()
  static getTotalFavoriteBids(state: ProcurementStateModel) {
    return state.favoriteBids.pageInfo.total;
  }

  @Action(FetchTotalBidsByTenderId)
  fetchTotalBidsByTenderId(
    { patchState }: StateContext<ProcurementStateModel>,
    { tenderId }: FetchTotalBidsByTenderId
  ) {
    return this.apollo
      .query({
        query: GET_TOTAL_BIDS_BY_TENDER_ID_QUERY,
        variables: { tenderId },
      })
      .pipe(tap(response => patchState({ totalBidsForTender: response.data['getTotalBidsByTenderId'] })));
  }

  @Action(FetchBidByIdAction)
  fetchBidById({ getState, patchState }: StateContext<ProcurementStateModel>, { bidId }: FetchBidByIdAction) {
    if (!bidId) {
      return of(null);
    }
    return this.apollo
      .query({
        query: FETCH_BID_BY_ID_QUERY,
        variables: { bidId },
      })
      .pipe(
        tap(response => {
          const currentBid = response.data['findBidById'];
          const bids = [...getState().bids.items];
          const bidItemIndex = bids.findIndex(bid => bid['_id'] === bidId);
          if (bidItemIndex !== -1) {
            bids[bidItemIndex] = currentBid;
          }
          const favoriteBids = [...getState().favoriteBids.items];
          const favoriteBidIndex = favoriteBids.findIndex(bid => bid['_id'] === bidId);
          if (favoriteBidIndex !== -1) {
            favoriteBids[favoriteBidIndex] = currentBid;
          }
          patchState({
            bids: {
              ...getState().bids,
              items: bids,
            },
            favoriteBids: {
              ...getState().favoriteBids,
              items: favoriteBids,
            },
            currentBid,
          });
        }),
        catchError(err => {
          console.error('Error Fetching Bid', err);
          return of(null);
        })
      );
  }

  @Action(FetchBidsPaginated)
  fetchBidsPaginated(
    { patchState }: StateContext<ProcurementStateModel>,
    { tenderId, offset, limit, sortOption, searchText, favorite, accepted }: FetchBidsPaginated
  ) {
    if (!tenderId) {
      return of(null);
    }

    return this.apollo
      .query({
        query: FETCH_BIDS_BY_TENDER_ID_QUERY,
        variables: { tenderId, favorite, accepted, offset, limit, sortOption, searchText },
      })
      .pipe(
        tap(response => {
          const bids: Bid[] = response.data['findPaginatedBidsByTenderId']['items'];
          const pageInfo = response.data['findPaginatedBidsByTenderId']['pageInfo'];

          const transformedBids = bids.map(bid => this.formatBidDetails(bid));

          const stateKey = favorite ? 'favoriteBids' : 'bids';
          patchState({
            [stateKey]: {
              items: transformedBids,
              pageInfo,
            },
          });
        }),
        catchError(err => {
          console.error(`Error Fetching ${favorite ? 'Favorite' : 'Non-Favorite'} Bids`, err);
          return of(null);
        })
      );
  }

  @Action(FetchNonFavoriteBidsPaginated)
  fetchNonFavoriteBidsPaginated(ctx: StateContext<ProcurementStateModel>, payload: FetchNonFavoriteBidsPaginated) {
    return this.fetchBidsPaginated(ctx, { ...payload, favorite: false, accepted: false });
  }

  @Action(FetchFavoriteBidsPaginated)
  fetchFavoriteBidsPaginated(ctx: StateContext<ProcurementStateModel>, payload: FetchFavoriteBidsPaginated) {
    return this.fetchBidsPaginated(ctx, { ...payload, favorite: true, accepted: false });
  }

  @Action(FetchAwardedBids)
  fetchAwardedBid({ patchState }: StateContext<ProcurementStateModel>, { tenderId }: FetchAwardedBids) {
    if (!tenderId) {
      return of(null);
    }
    return this.apollo
      .query({
        query: FETCH_BIDS_BY_TENDER_ID_QUERY,
        variables: { tenderId, favorite: false, accepted: true, offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT },
      })
      .pipe(
        tap(response => {
          const awardedBids: Bid[] = response.data['findPaginatedBidsByTenderId']['items'];
          patchState({ acceptedBids: awardedBids.map(bid => this.formatBidDetails(bid)) });
        }),
        catchError(err => {
          console.error('Error Fetching Awarded Bid', err);
          return of(null);
        })
      );
  }

  /**
   * @deprecated - This action is deprecated and will be removed in the future therefore change all usages of this action
   *  to fetchBidsPaginated. Do not use this in new code.
   */
  @Action(FetchBidsByTenderIdAction)
  fetchBidByTenderId({ patchState }: StateContext<ProcurementStateModel>, { tenderId }: FetchBidsByTenderIdAction) {
    if (!tenderId) {
      return of(null);
    }
    return this.apollo
      .query({
        query: FETCH_BIDS_BY_TENDER_ID_QUERY,
        variables: { tenderId, favorite: false, accepted: false, offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT },
      })
      .pipe(
        tap(response => {
          const bids: Bid[] = response.data['findPaginatedBidsByTenderId']['items'];
          const bidItems: Bid[] = [];
          const favoriteBids: Bid[] = [];
          const acceptedBids: Bid[] = [];

          for (const bid of bids) {
            const date = new Date(bid.createdAt);
            const hours = date.getUTCHours().toString().padStart(2, '0');
            const minutes = date.getUTCMinutes().toString().padStart(2, '0');
            const time = `${hours}${minutes}h`;
            let totalAmount = 0;

            bid.bidMetaData.shipmentsData.forEach(shipment => {
              shipment.serviceMetaData.forEach(service => {
                if (service.ratesSummary) {
                  totalAmount += service.ratesSummary['total'];
                } else {
                  totalAmount = MASKED_FLAG;
                }
              });
            });

            const tempBid = {
              ...bid,
              date: date.toISOString().split('T')[0],
              time: time,
              bidId: bid._id,
              totalAmount,
            };
            bidItems.push(tempBid);

            if (bid.bidMetaData.favorite) {
              favoriteBids.push(tempBid);
            }
            if (bid.bidMetaData.accepted) {
              acceptedBids.push(tempBid);
            }
          }
          patchState({
            bids: {
              items: bidItems,
            },
            favoriteBids: {
              items: favoriteBids,
            },
            acceptedBids: acceptedBids.map(bid => this.formatBidDetails(bid)),
          });
        }),
        catchError(err => {
          console.error('Error Fetching Bids', err);
          return of(null);
        })
      );
  }

  @Action(MarkAsFavoriteBidAction)
  async saveMarkAsFavoriteBid(
    { getState, patchState }: StateContext<ProcurementStateModel>,
    { bidId }: MarkAsFavoriteBidAction
  ) {
    if (!bidId) {
      return of(null);
    }
    return this.apollo
      .mutate({
        mutation: MARK_BID_AS_FAVORITE_MUTATION,
        variables: {
          bidId,
        },
      })
      .pipe(catchError(async err => console.error('Error marking bid as favorite', err)));
  }

  @Action(AwardBidAction)
  async awardBidOption(
    { getState, patchState }: StateContext<ProcurementStateModel>,
    { bidId, bidOptionId }: AwardBidAction
  ) {
    if (!bidId || !bidOptionId) {
      return of(null);
    }
    return this.apollo
      .mutate({
        mutation: ACCEPT_BID_OPTION_MUTATION,
        variables: {
          bidId,
          bidOptionId,
        },
      })
      .pipe(
        catchError(err => {
          console.error('error awarding bid', err);
          this.notificationService.showError('Error awarding bid');
          return of(null);
        })
      );
  }

  @Action(VoteBidAction)
  async voteBidOption({ getState, patchState }: StateContext<ProcurementStateModel>, { bidId }: VoteBidAction) {
    if (!bidId) {
      return of(null);
    }
    const currentVote = getState().currentVote;
    if (!currentVote) {
      patchState({
        currentVote: {
          bidId,
          voted: true,
        },
      });
    } else if (currentVote.bidId === bidId) {
      patchState({
        currentVote: null,
      });
    }
    return this.apollo
      .mutate({
        mutation: VOTE_BID_OPTION_MUTATION,
        variables: {
          bidId,
        },
      })
      .pipe(catchError(async err => console.error('error voting bid', err)));
  }

  @Action(UpdateTenderStatusAction)
  updateTenderStatus({ patchState }: StateContext<ProcurementStateModel>, { tenderStatus }: UpdateTenderStatusAction) {
    patchState({
      currentTenderStatus: tenderStatus,
    });
  }

  @Action(SetCurrentVoteAction)
  setCurrentVote({ patchState }: StateContext<ProcurementStateModel>, { currentVote }: SetCurrentVoteAction) {
    patchState({
      currentVote,
    });
  }

  @Action(Logout)
  logout({ patchState }: StateContext<ProcurementStateModel>) {
    patchState({
      currentVote: null,
    });
  }

  @Action(RejectAllBidsAction)
  rejectAllBids(_ctx: StateContext<ProcurementStateModel>, { tenderId, rejectionReason }: RejectAllBidsAction) {
    return this.apollo
      .mutate<boolean>({
        mutation: REJECT_ALL_BIDS_MUTATION,
        variables: { tenderId, reason: rejectionReason },
      })
      .pipe(
        tap(result => {
          if (result) {
            this.notificationService.showSuccess('All Bids Rejected Successfully');
          } else {
            this.notificationService.showError('Rejecting All Bids was Unsuccessful');
          }
        }),
        catchError(err => {
          console.error('error rejecting all bids', err);
          this.notificationService.showError('An Error Occurred While Rejecting All Bids');
          return of(null);
        })
      );
  }

  @Action(ClearBids)
  clearBids({ patchState }: StateContext<ProcurementStateModel>) {
    patchState(defaults);
  }

  /*
   * Formats a single bid object from the query response to match the UI requirements
   */
  private formatBidDetails(bid: Bid): Bid {
    const date = new Date(bid.createdAt);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const time = `${hours}${minutes}h`;
    let totalAmount = 0;

    bid.bidMetaData.shipmentsData.forEach(shipment => {
      shipment.serviceMetaData.forEach(service => {
        if (service.ratesSummary) {
          totalAmount += service.ratesSummary['total'];
        } else {
          totalAmount = MASKED_FLAG;
        }
      });
    });

    return {
      ...bid,
      date: date.toISOString().split('T')[0],
      time: time,
      bidId: bid._id,
      totalAmount,
    };
  }
}
