<div class="general-details-container">
  <div class="col-1">
    <img src="./assets/icons/globe.svg" alt="home icon" />
  </div>
  <div class="col-2">
    <div class="row-1">General Details</div>
    <div class="row-2 content-style">
      <hmt-key-value [keyValue]="jobFileName" style="width: 100%"></hmt-key-value>
      <hmt-key-value [keyValue]="tenderReferenceNumber" style="width: 100%"></hmt-key-value>
      <div class="tender-status-container">
        <div>{{ tenderStatus.key }}</div>
        <div class="status" [ngClass]="getStatusClass(currentTenderStatus$ | async)">
          {{ tenderStatus.value | statusToLower: ' ' }}
        </div>
      </div>
      <hmt-key-value [keyValue]="jobReferenceNumber" style="width: 100%"></hmt-key-value>
      <hmt-key-value [keyValue]="tenderStartedAt" style="width: 100%"></hmt-key-value>
      <hmt-key-value [keyValue]="tenderExpiresOn" style="width: 100%"></hmt-key-value>
      <hmt-key-value [keyValue]="remainingTime" style="width: 100%"></hmt-key-value>
      <hmt-key-value [keyValue]="numberOfBids" style="width: 100%"></hmt-key-value>
    </div>
  </div>
</div>
