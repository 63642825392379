import { Currency } from 'app/modules/organizations-manager/models/currency.model';
import { AdditionalCharge } from 'app/modules/procurement/models/additional-charges.model';
import { BidService, BidServiceData } from 'app/modules/procurement/models/bid-service-data.model';
import { BiddingDrawerConfig } from 'app/modules/procurement/models/bidding-drawer-config.model';
import { FCLRate, LCLRate } from 'app/modules/procurement/models/rate.model';

const CREATE_BID_PREFIX = '[Create Bid]';

export class UpdateSelectedCurrencies {
  static readonly type = `${CREATE_BID_PREFIX} Update Selected Currencies`;
  constructor(public currencies: Currency[]) {}
}

export class SwitchCurViewingBid {
  static readonly type = `${CREATE_BID_PREFIX} Switch Current Viewing Bid`;
  constructor(public bid: BidServiceData) {}
}

export class SwitchCurViewingService {
  static readonly type = `${CREATE_BID_PREFIX} Switch Current Viewing Service`;
  constructor(public service: BidService) {}
}

export class CreateNewBid {
  static readonly type = `${CREATE_BID_PREFIX} Add New Bid`;
  constructor(public bid: BidServiceData) {}
}

export class AddCurViewingServiceAdditionalCharges {
  static readonly type = `${CREATE_BID_PREFIX} Update Current Viewing Service Additional Charges`;
  constructor(public newAdditionalCharges: AdditionalCharge) {}
}

export class RemoveCurViewingServiceAdditionalCharge {
  static readonly type = `${CREATE_BID_PREFIX} Remove Current Viewing Service Additional Charge`;
  constructor(public index: number) {}
}

export class UpdateCurViewingServiceAdditionalCharge {
  static readonly type = `${CREATE_BID_PREFIX} Update Current Viewing Service Additional Charge`;
  constructor(
    public index: number,
    public newAdditionalCharge: AdditionalCharge
  ) {}
}

export class UpdateSelectedCurrency {
  static readonly type = `${CREATE_BID_PREFIX} Update Selected Currency`;
  constructor(public currency: Currency) {}
}

export class UpdateSubTotal {
  static readonly type = `${CREATE_BID_PREFIX} Update Sub Total`;
  constructor(public subTotal: number) {}
}

export class UpdateCurrentViewingServiceFCLRates {
  static readonly type = `${CREATE_BID_PREFIX} Update FCL Rates`;
  constructor(public fclRates: FCLRate[]) {}
}

export class UpdateCurrentViewingServiceLCLRates {
  static readonly type = `${CREATE_BID_PREFIX} Update LCL Rates`;
  constructor(
    public lclRates: LCLRate,
    public subTotal: number
  ) {}
}

export class ResetBidState {
  static readonly type = `${CREATE_BID_PREFIX} Reset Bid State`;
}

export class FetchMyBids {
  static readonly type = `${CREATE_BID_PREFIX} Fetch My Bids`;
  constructor(public jobRefId: string) {}
}

export class SetAllBids {
  static readonly type = `${CREATE_BID_PREFIX} Set All Bids`;
  constructor(public bids: BidServiceData[]) {}
}

export class SetBidConfig {
  static readonly type = `${CREATE_BID_PREFIX} Set Bid Config`;
  constructor(public config: BiddingDrawerConfig) {}
}

export class WithdrawBid {
  static readonly type = `${CREATE_BID_PREFIX} Withdraw Bid`;
  constructor(public bidId: string) {}
}

export class SetFormIsValid {
  static readonly type = `${CREATE_BID_PREFIX} Set Form Is Valid`;
  constructor(public isValid: boolean) {}
}
